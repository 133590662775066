import logo from './newLogo.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          The Minty Deer is bleating all over the internet!
        </p>
      </header>
    </div>
  );
}

export default App;
